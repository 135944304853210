module.exports = [{
      plugin: require('/Users/sims/Desktop/my-app/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-93584100-2","head":true,"anonymize":true},
    },{
      plugin: require('/Users/sims/Desktop/my-app/gatsby-site/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-93584100-2","head":true},
    },{
      plugin: require('/Users/sims/Desktop/my-app/gatsby-site/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1074079142784327"},
    },{
      plugin: require('/Users/sims/Desktop/my-app/gatsby-site/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
